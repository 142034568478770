/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description = "", lang = "en", meta = [], breadcrumb, title, image, meta_title, location = {} }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const metaImagePath = image || "/scrummate.png";
    const metaTitle = meta_title ? meta_title : title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;

    let jsonLd = null;
    if(breadcrumb){
        const safeBreadcrumbList = Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb];
        jsonLd = {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": safeBreadcrumbList.map((name, index)=>{
                const safeName = typeof name === "string" ? name : name.name;
                const safePathname = typeof name === "string" ? location.pathname : name.pathname;
                return {
                    "@type": "ListItem",
                    "position": index + 1,
                    "name": safeName,
                    "item": `${site.siteMetadata.siteUrl}${safePathname}`
                }
            })
        };
    }

    let metaImageUrl = site.siteMetadata.siteUrl + metaImagePath;
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                {
                    name: "google-site-verification",
                    content: "djWZCQn2xrdueewNQMOZUOOFudh2mGwIqk02TUh1fUs",
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:locale`,
                    content: `en_US`,
                },
                {
                    property: `og:image`,
                    content: metaImageUrl,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: metaImageUrl,
                },
            ].concat(meta)}>{jsonLd && <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>}</Helmet>
    );
}

export default SEO;
